import { useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import {
  getPositionsPermissionsQuery,
  getPositionsQuery,
} from "../../../queries";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { useTranslation } from "react-i18next";
import { Input } from "../../../Components/atoms/input";
import PermissionsGroup from "./modules/PermissionGroup";
import { editPositions } from "../../../api";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  CardHeader,
  Container,
  Form,
  Spinner,
} from "reactstrap";
import { toast, ToastContainer } from "react-toastify";
import { useNavigate } from "react-router-dom";

const values = {
  project: null,
  nameEn: "",
  nameRu: "",
  nameUz: "",
  nameUzc: "",
  permissionIds: [],
};
const schema = createSchema({
  nameEn: "name",
  nameRu: "name",
  nameUz: "name",
  nameUzc: "name",
});

const Roles = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate()
  const { data } = useQuery({
    ...getPositionsQuery(),
  });
  const { data: permissions } = useQuery({
    ...getPositionsPermissionsQuery(),
  });

  const {
    setValue,
    formState: { errors },
    control,
    watch,
    reset,
    handleSubmit,
  } = useHookForm(values, schema);

  const selectedRole = watch("type");
  const selectedProject = watch("project");
  const queryClient = useQueryClient();
  const [filterPermission, setFilterPermission] = useState([]);
  const [rolePermissionIds, setRolePermissionIds] = useState([]);
  const [permissionIds, setPermissionIds] = useState([]);

  const handlePermissionsChange = (newPermissions) => {
    setPermissionIds(newPermissions);
  };
  const [projectData, setProjectData] = useState(null);

  useEffect(() => {
    if (selectedProject && data) {
      const selectedItem = data.find(
        (item) => item.id === selectedProject.value
      );

      if (selectedItem) {
        setProjectData(selectedItem);
        setValue("nameEn", selectedItem.nameEn);
        setValue("nameRu", selectedItem.nameRu);
        setValue("nameUz", selectedItem.nameUz);
        setValue("nameUzc", selectedItem.nameUzc);
      }
    }
  }, [selectedProject, data, setValue]);

  useEffect(() => {}, [projectData]);

  useEffect(() => {
    if (data && selectedRole) {
      const rolePermissions =
        selectedRole.value === 1
          ? data.filter((item) => item.project === 1)
          : selectedRole.value === 2
          ? data.filter((item) => item.project === 2)
          : [];

      setFilterPermission(
        rolePermissions.map((perm) => ({
          value: perm.id,
          label: perm.nameRu,
        }))
      );

      setRolePermissionIds(rolePermissions.map((perm) => perm.id));

      data.find((item) => item.id === selectedRole.value);
    }
  }, [selectedRole, data]);

  const Submit = async () => {
    setLoading(true);
    const res = {
      project: selectedRole?.value,
      nameEn: watch("nameEn"),
      nameRu: watch("nameRu"),
      nameUz: watch("nameUz"),
      nameUzc: watch("nameUzc"),
      permissionIds: permissionIds,
    };

    try {
      await editPositions(selectedProject.value, res);
      toast.success(t("Successfully"), { theme: "colored" });
      queryClient.invalidateQueries({ queryKey: ["positions"] });
      queryClient.invalidateQueries({ queryKey: ["permissions"] });
      queryClient.invalidateQueries({queryKey: ["profile-info"]})
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="page-content">
      <ToastContainer position="top-center" />
      <Container fluid>
        <Card>
          <CardHeader>
         <div className="d-flex align-items-center">
            
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {t("managing")}
                  </h5>
                </div>
          </CardHeader>
          <CardBody>
            <Form onSubmit={handleSubmit(Submit)}>
              <Controller
                name="type"
                control={control}
                className="bg-white"
                render={({ field }) => (
                  <Select
                    {...field}
                    options={[
                      { value: 1, label: t("administrators") },
                      { value: 2, label: t("merchant") },
                    ]}
                    placeholder={t("position")}
                  />
                )}
              />
              {selectedRole && (
                <div className="mt-4">
                  <Controller
                    name="project"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={filterPermission}
                        placeholder={t("role_in_the_system")}
                      />
                    )}
                  />
                </div>
              )}

              {projectData && (
                <div className="mt-3">
                  <div className="d-flex align-items-center gap-4 w-75">
                    <div className="w-100">
                      <div>
                        <Input
                          control={control}
                          name="nameEn"
                          label={"roles.give_role_name_eng"}
                          errors={errors}
                        />
                      </div>
                      <div className="mt-2">
                        <Input
                          control={control}
                          name="nameRu"
                          label={"roles.give_role_name_ru"}
                          errors={errors}
                        />
                      </div>
                    </div>
                    <div className="w-100">
                      <div>
                        <Input
                          control={control}
                          name="nameUz"
                          label={"roles.give_role_name_uz"}
                          errors={errors}
                        />
                      </div>
                      <div className="mt-2">
                        <Input
                          control={control}
                          name="nameUzc"
                          label={"roles.give_role_name_uzc"}
                          errors={errors}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div hidden={!projectData} className="mt-3">
                <PermissionsGroup
                  allPermissions={permissions}
                  currentRole={projectData}
                  onChange={handlePermissionsChange}
                />
              </div>

              <div className="d-flex justify-content-end mb-2">
                <Button hidden={!projectData} type="submit">
                  {loading ? <Spinner size={"sm"} /> : t("save")}
                </Button>
              </div>
            </Form>
          </CardBody>
        </Card>
      </Container>
    </div>
  );
};

export default Roles;
