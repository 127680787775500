import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Label,
  Input as InputFile,
} from "reactstrap";
import { Input } from "../../../Components/atoms/input";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getOrganizationQuery,
  getFormalTypeQuery,
  getRegionsQuery,
} from "../../../queries";
import { prettify } from "../../../helpers/price-formater";
import Cleave from "cleave.js/react";
import "cleave.js/dist/addons/cleave-phone.uz";
import OrgContractEdit from "./org-contract-edit";
import { editOrganization } from "../../../api/organization";
import { toast, ToastContainer } from "react-toastify";

const schema = createSchema({});
const values = {
  accountantFullName: "",
  accountantPinfl: "",
  address: "",
  bankAccountNumber: "",
  bankName: "",
  description: "",
  districtId: "",
  email: "",
  formalTypeId: "",
  id: "",
  mfo: "",
  oked: "",
  organizationName: "",
  organizationOwnerPINFL: "",
  organizationTIN: "",
  ownerFullName: "",
  phone: "",
  regionId: "",
  serviceRegionIds: [],
  vatNumber: "",
};

const OrganizationsEdit = () => {
  const [loading, setLoading] = useState(false);
  const [formType, setFormType] = useState("");
  const [regionIds, setRegionIds] = useState("");
  const [districtIds, setDistrictIds] = useState("");
  const [districts, setDistricts] = useState([]);
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [description, setDescription] = useState("");
  const [selectedRegions, setSelectedRegions] = useState([]);
  const QueryClient = useQueryClient();
const navigate = useNavigate()
  const { t } = useTranslation();
  const { id } = useParams();
  const { data: organization } = useQuery({
    ...getOrganizationQuery(id),
  });
  const { data: formalType } = useQuery({
    ...getFormalTypeQuery(),
  });
  const { data: regions } = useQuery({
    ...getRegionsQuery(),
  });

  const DistrictDatas = async () => {
    const { result } = await fetch(
      `
      https://f.taqsim.uz/api/regions/${regionIds}/districts`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
          "Content-Type": "application/json",
        },
      }
    ).then((res) => res.json());
    setDistricts(result?.districts);
  };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useHookForm(values, schema);

  const onSubmit = handleSubmit((res) => {
    setLoading(true);
    editOrganization(res, id)
      .then(() => {
        setLoading(false);
        QueryClient.invalidateQueries(["user-organizations"]);
        toast("Организация изменена", {type: "success", theme: "colored", position: "top-center", autoClose: "2000"})
      })
      .catch((errors) => console.log(errors));
  });

  const getFileUpload = (e, key) => {
    e.preventDefault();
    fetch(`https://f.taqsim.uz/api/files/organizations/${key}`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      const blob = await res.blob();
      const url = window.URL.createObjectURL(blob);
      const filename = url.split("/").pop();
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.setAttribute("download", filename);
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    });
  };

  const handleRegionCheckbox = (id) => {
    setSelectedRegions((prevSelectedRegions) =>
      prevSelectedRegions.includes(id)
        ? prevSelectedRegions.filter((regionId) => regionId !== id)
        : [...prevSelectedRegions, id]
    );
  };

  useEffect(() => {
    if (organization) {
      !phone && setPhone(organization?.contact?.phoneNumber);
      !description && setDescription(organization?.contact?.description);
      !regionIds && setRegionIds(organization.regionId);
      regionIds && DistrictDatas();
      !districtIds && setDistrictIds(organization.districtId);
      !address && setAddress(organization?.address);
      !formType && setFormType(organization.formalTypeId);
      !selectedRegions?.length > 0 &&
        setSelectedRegions(organization?.serviceRegionIds);

      const values = {
        accountantFullName: organization.accountantFullName,
        accountantPinfl: organization.accountantPinfl?.replace(
          /(\d{4})(?=\d)/g,
          "$1 "
        ),
        address,
        bankAccountNumber: organization.organizationBank?.accountNumber?.replace(
          /(\d{4})(?=\d)/g,
          "$1 "
        ),
        bankName: organization.organizationBank?.servingBankName,
        description,
        districtId: districtIds,
        email: organization.contact?.email,
        formalTypeId: formType,
        id: parseInt(id),
        mfo: organization.organizationBank?.mfo,
        oked: prettify(organization.organizationBank?.oked),
        organizationOwnerPINFL: organization.organizationOwnerPINFL?.replace(
          /(\d{4})(?=\d)/g,
          "$1 "
        ),
        organizationTIN: organization.organizationTIN,
        ownerFullName: organization.ownerFullName,
        phone,
        regionId: regionIds,
        serviceRegionIds: selectedRegions,
        vatNumber: organization.vatNumber?.replace(/(\d{4})(?=\d)/g, "$1 "),
        longitude: organization.longitude,
        latitude: organization.latitude
      };
      reset(values);
    }
  }, [
    organization,
    regionIds,
    selectedRegions,
    formType,
    districtIds,
    address,
    phone,
    description,
  ]);

  document.title = "Organizations Edit | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
      <ToastContainer />
        <Row>
          <Col xl={11}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center gap-3">
                <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {organization?.organizationName}
                  </h5>
                  <div className="flex-shrink-0">
                    {organization?.state === 2 ? (
                      <div className="text-danger">{t("inactive")}</div>
                    ) : (
                      <div className="text-success">{t("active")}</div>
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <OrgContractEdit organization={organization} />
                  <Card>
                    <Row className="d-flex flex-column align-items-start p-4 gap-4">
                      <h4
                        className="card-title flex-grow-1 mb-0 text-dark"
                        style={{ fontWeight: 600 }}
                      >
                        {"Общая информация о компании"}
                      </h4>
                      <Row className="d-flex justify-content-between gap-3">
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {"Название организации"}
                          </Label>
                          <InputFile
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                            defaultValue={organization?.organizationName}
                            disabled
                          />
                        </Col>
                        <Col xl={5}>
                          <Label>{"Тип организации"}</Label>
                          <select
                            className="form-select mb-3"
                            aria-label="Default select example"
                            onChange={(e) =>
                              setFormType(parseInt(e.target.value))
                            }
                            value={formType}
                            style={{ marginBottom: 0 }}
                          >
                            {formalType?.items.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            ИНН организации
                          </Label>
                          <Input
                            name="organizationTIN"
                            errors={errors}
                            control={control}
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            Номер плательщика НДС
                          </Label>
                          <Input
                            name="vatNumber"
                            errors={errors}
                            control={control}
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                        {organization?.files?.map((item) =>
                          item.type === 1 || item.type === 4 ? (
                            <Col xl={5} key={item.id}>
                              <Label>
                                {item.type === 1
                                  ? "Свидетельство о регистрации организации"
                                  : "Устав и/или учредительный договор"}
                              </Label>
                              <div className="input-group">
                                <InputFile
                                  type="text"
                                  className="form-control"
                                  placeholder="Username"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={item.key}
                                  disabled={true}
                                />
                                <a
                                  className="btn btn-secondary"
                                  onClick={(e) => getFileUpload(e, item.key)}
                                  href={`/files/organizations/${item.key}`}
                                  download
                                >
                                  {"Скачать"}
                                </a>
                              </div>
                            </Col>
                          ) : null
                        )}
                         <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {t("coordinate_lat")}
                          </Label>
                          <Input
                            name="latitude"
                            errors={errors}
                            control={control}
                            inputProps={{
                              type: "number",
                              placeholder: t("enter_the_coordinate")
                            }}
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {t("coordinate_long")}
                          </Label>
                          <Input
                            name="longitude"
                            errors={errors}
                            control={control}
                            inputProps={{
                              type: "number",
                              placeholder: t("enter_the_coordinate")
                            }}
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                  <Card>
                    <Row className="d-flex flex-column align-items-start p-4 gap-4">
                      <h4
                        className="card-title flex-grow-1 mb-0 text-dark"
                        style={{ fontWeight: 600 }}
                      >
                        {"Адрес компании"}
                      </h4>
                      <Row className="d-flex justify-content-between gap-3">
                        <Col xl={5}>
                          <Label>{"Регион"}</Label>
                          <select
                            className="form-select mb-3"
                            aria-label="Default select example"
                            onChange={(e) =>
                              setRegionIds(parseInt(e.target.value))
                            }
                            value={regionIds}
                            style={{ marginBottom: 0 }}
                          >
                            {regions?.regions?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col xl={5}>
                          <Label>{"Район"}</Label>
                          <select
                            className="form-select mb-3"
                            aria-label="Default select example"
                            onChange={(e) =>
                              setDistrictIds(parseInt(e.target.value))
                            }
                            value={districtIds}
                          >
                            {districts?.map((item) => (
                              <option value={item.id} key={item.id}>
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col xl={12}>
                          <Label>{"Адрес"}</Label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea5"
                            rows="4"
                            value={address}
                            onChange={(e) => setAddress(e.target.value)}
                          ></textarea>
                        </Col>
                        <Label>{"Наличие филиалов и агентов в регионах"}</Label>
                        <Col xl={12} className="d-flex flex-wrap">
                          {regions?.regions?.map((item) => (
                            <div
                              className="form-check mb-3"
                              key={item.id}
                              style={{ width: "50%" }}
                            >
                              <InputFile
                                className="form-check-input"
                                type="checkbox"
                                id={`formCheck-${item.id}`}
                                checked={selectedRegions?.includes(item.id)}
                                onChange={() => handleRegionCheckbox(item.id)}
                                disabled
                              />
                              <Label
                                className="form-check-label"
                                htmlFor={`formCheck-${item.id}`}
                              >
                                {item.name}
                              </Label>
                            </div>
                          ))}
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                  <Card>
                    <Row className="d-flex flex-column align-items-start p-4 gap-4">
                      <h4
                        className="card-title flex-grow-1 mb-0 text-dark"
                        style={{ fontWeight: 600 }}
                      >
                        {"Информация о руководителе"}
                      </h4>
                      <Row className="d-flex justify-content-between gap-3">
                        <Col xl={5}>
                          <div>
                            <Label
                              htmlFor="valueInput"
                              className="form-label p-0"
                            >
                              {"Ф.И.О. руководителя"}
                            </Label>
                            <Input
                              name="ownerFullName"
                              errors={errors}
                              control={control}
                              type="text"
                              className="form-control mb-3"
                              id="valueInput"
                            />
                          </div>
                        </Col>
                        <Col xl={5}>
                          <div>
                            <Label
                              htmlFor="valueInput"
                              className="form-label p-0"
                            >
                              {"ПИНФЛ руководителя"}
                            </Label>
                            <Input
                              name="organizationOwnerPINFL"
                              errors={errors}
                              control={control}
                              type="text"
                              className="form-control mb-3"
                              id="valueInput"
                            />
                          </div>
                        </Col>
                        <Col xl={5}>
                          <div>
                            <Label
                              htmlFor="valueInput"
                              className="form-label p-0"
                            >
                              {"Ф.И.О. главного бухгалтера (при наличии)"}
                            </Label>
                            <Input
                              name="accountantFullName"
                              errors={errors}
                              control={control}
                              type="text"
                              className="form-control mb-3"
                              id="valueInput"
                            />
                          </div>
                        </Col>
                        <Col xl={5}>
                          <div>
                            <Label
                              htmlFor="valueInput"
                              className="form-label p-0"
                            >
                              {"ПИНФЛ глав. бухгалтера"}
                            </Label>
                            <Input
                              name="accountantPinfl"
                              errors={errors}
                              control={control}
                              type="text"
                              className="form-control mb-3"
                              id="valueInput"
                            />
                          </div>
                        </Col>
                        {organization?.files?.map((item) =>
                          item.type === 2 ? (
                            <Col xl={5} key={item.id}>
                              <Label>{"Паспортные данные руководителя"}</Label>
                              <div className="input-group">
                                <InputFile
                                  type="text"
                                  className="form-control"
                                  placeholder="Username"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={item.key}
                                  disabled={true}
                                />
                                <a
                                  className="btn btn-secondary"
                                  onClick={(e) => getFileUpload(e, item.key)}
                                  href={`/files/organizations/${item.key}`}
                                  download
                                >
                                  {"Скачать"}
                                </a>
                              </div>
                            </Col>
                          ) : null
                        )}
                      </Row>
                    </Row>
                  </Card>
                  <Card>
                    <Row className="d-flex flex-column align-items-start p-4 gap-4">
                      <h4
                        className="card-title flex-grow-1 mb-0 text-dark"
                        style={{ fontWeight: 600 }}
                      >
                        {"Учредители"}
                      </h4>
                      <Row className="d-flex justify-content-between gap-3">
                        {organization?.founders?.map((item) => (
                          <>
                            <Col xl={5}>
                              <Label>{"Данные учредителя"}</Label>
                              <select
                                className="form-select mb-3"
                                aria-label="Default select example"
                                disabled
                              >
                                {item?.type === 1 ? (
                                  <>
                                    <option value={1}>
                                      {"Физическое лицо"}
                                    </option>
                                    <option value={2}>
                                      {"Юридическое лицо"}
                                    </option>
                                  </>
                                ) : (
                                  <>
                                    <option value={2}>
                                      {"Юридическое лицо"}
                                    </option>
                                    <option value={1}>
                                      {"Физическое лицо"}
                                    </option>
                                  </>
                                )}
                              </select>
                            </Col>
                            <Col xl={5} key={item.id}>
                              <div>
                                <Label
                                  htmlFor="valueInput"
                                  className="form-label p-0"
                                >
                                  {"ПИНФЛ или ИНН учредителя"}
                                </Label>
                                <InputFile
                                  type="text"
                                  className="form-control mb-3"
                                  id="valueInput"
                                  defaultValue={item.founderPinflOrTin.replace(
                                    /(\d{4})(?=\d)/g,
                                    "$1 "
                                  )}
                                  disabled
                                />
                              </div>
                            </Col>
                            <Col xl={5} key={item.id}>
                              <Label>{"Паспорт"}</Label>
                              <div className="input-group">
                                <InputFile
                                  type="text"
                                  className="form-control"
                                  placeholder="Username"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={item.fileId}
                                  disabled
                                />
                                <a
                                  className="btn btn-secondary"
                                  onClick={(e) => getFileUpload(e, item.fileId)}
                                  href={`/files/organizations/${item.fileId}`}
                                  download
                                >
                                  {"Скачать"}
                                </a>
                              </div>
                            </Col>
                          </>
                        ))}
                      </Row>
                    </Row>
                  </Card>
                  <Card>
                    <Row className="d-flex flex-column align-items-start p-4 gap-4">
                      <h4
                        className="card-title flex-grow-1 mb-0 text-dark"
                        style={{ fontWeight: 600 }}
                      >
                        {"Информация о банке"}
                      </h4>
                      <Row className="d-flex justify-content-between gap-3">
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {"Обслуживающий банк"}
                          </Label>
                          <Input
                            name="bankName"
                            errors={errors}
                            control={control}
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {"МФО банка"}
                          </Label>
                          <Input
                            name="mfo"
                            errors={errors}
                            control={control}
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {"Расчетный счет"}
                          </Label>
                          <Input
                            name="bankAccountNumber"
                            errors={errors}
                            control={control}
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {"ОКЭД"}
                          </Label>
                          <Input
                            name="oked"
                            errors={errors}
                            control={control}
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                  <Card>
                    <Row className="d-flex flex-column align-items-start p-4 gap-4">
                      <h4
                        className="card-title flex-grow-1 mb-0 text-dark"
                        style={{ fontWeight: 600 }}
                      >
                        {"Финансовые показатели"}
                      </h4>
                      <Row className="d-flex gap-4">
                        {organization?.files?.map((item) =>
                          item.type === 5 || item.type === 6 ? (
                            <Col xl={8} key={item.id}>
                              <Label>
                                {item.type === 6
                                  ? "Положительное аудиторское заключение за последний финансовый год"
                                  : "Справка об отсутствии задолженности перед бюджетом и внебюджетными фондами"}
                              </Label>
                              <div className="input-group">
                                <InputFile
                                  type="text"
                                  className="form-control"
                                  placeholder="Username"
                                  aria-label="Username"
                                  aria-describedby="basic-addon1"
                                  value={item.key}
                                  disabled={true}
                                />
                                <a
                                  className="btn btn-secondary"
                                  onClick={(e) => getFileUpload(e, item.key)}
                                  href={`/files/organizations/${item.key}`}
                                  download
                                >
                                  {"Скачать"}
                                </a>
                              </div>
                            </Col>
                          ) : null
                        )}
                      </Row>
                    </Row>
                  </Card>
                  <Card>
                    <Row className="d-flex flex-column align-items-start p-4 gap-4">
                      <h4
                        className="card-title flex-grow-1 mb-0 text-dark"
                        style={{ fontWeight: 600 }}
                      >
                        {"Доп. информаиция об организации"}
                      </h4>
                      <Row className="d-flex justify-content-between gap-4">
                        <Col xl={5}>
                          <div className="mb-3 mb-xl-0">
                            <label
                              htmlFor="cleave-phone"
                              className="form-label"
                            >
                              Phone
                            </label>
                            <Cleave
                              placeholder="998xxxxxxxxx"
                              value={phone}
                              onChange={(e) => setPhone(e.target.value)}
                              className="form-control"
                            />
                          </div>
                        </Col>
                        <Col xl={5}>
                          <Label
                            htmlFor="valueInput"
                            className="form-label p-0"
                          >
                            {"E-mail"}
                          </Label>
                          <Input
                            name="email"
                            errors={errors}
                            control={control}
                            type="text"
                            className="form-control mb-3"
                            id="valueInput"
                          />
                        </Col>
                        <Col xl={12}>
                          <Label>{"Описание"}</Label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea5"
                            rows="4"
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                          ></textarea>
                        </Col>
                      </Row>
                    </Row>
                  </Card>
                  <Button
                    color="success"
                    className="btn btn-success w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OrganizationsEdit;