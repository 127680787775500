import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";

const NoAccessPage = () => {
  const { t } = useTranslation();

  return (
    <div className="page-content text-center">
      <h3>{t("Have_no_access")}</h3>
      <Link to="/">
        <Button className="btn btn-primary">
          {t("go_home")}
        </Button>
      </Link>
    </div>
  );
};

export default NoAccessPage;
