import React, { useEffect, useState, useMemo } from "react";
import { Accordion, AccordionBody, AccordionHeader, AccordionItem, Button, Card, Collapse, Input, Label } from "reactstrap";
import useHookForm from "../../../../hooks/useHookForm";
import createSchema from "../../../../helpers/createSchema";
import { useTranslation } from "react-i18next";

const schema = createSchema({});
const values = {
  permissionIds: [],
};



const PermissionsGroup = ({ allPermissions, currentRole, onChange }) => {
  const [permissions, setPermissions] = useState([]);
  const [groupNames, setGroupNames] = useState({});
  const [openGroup, setOpenGroup] = useState(null);
  const { t } = useTranslation();

  const {
    control,
    watch,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useHookForm(values, schema);

  useEffect(() => {
    onChange(permissions);
  }, [permissions, onChange]);

  useEffect(() => {
    if (currentRole?.permissionIds) {
      setPermissions([...currentRole.permissionIds]);
    }
  }, [currentRole]);

  const groupedPermissions = useMemo(() => {
    const filteredPermissions = allPermissions?.filter(
      (perm) => perm.projectType === currentRole?.project
    );

    const groups = {};
    filteredPermissions?.forEach((item) => {
      const groupName = item.name.split(".")[0];
      if (!groups[groupName]) {
        groups[groupName] = {
          groupName,
          elements: [],
        };
      }
      groups[groupName].elements.push(item);
    });

    return Object.values(groups);
  }, [allPermissions, currentRole]);

  useEffect(() => {
    const names = {};
    groupedPermissions?.forEach((group) => {
      names[group.groupName] = false;
    });
    setGroupNames(names);
  }, [groupedPermissions]);

  useEffect(() => {
    const updatedGroupNames = { ...groupNames };

    groupedPermissions?.forEach(({ groupName, elements }) => {
      const allSelected = elements.every((item) => permissions.includes(item.id));
      const someSelected = elements.some((item) => permissions.includes(item.id));

      updatedGroupNames[groupName] = allSelected
        ? true
        : someSelected
        ? null
        : false;
    });

    setGroupNames(updatedGroupNames);
  }, [permissions, groupedPermissions]);

  const handleGroupCheckbox = (value, group) => {
    if (value) {
      const newPermissions = [
        ...permissions,
        ...group.elements.map((el) => el.id),
      ];
      setPermissions([...new Set(newPermissions)]);
    } else {
      const groupIds = new Set(group.elements.map((el) => el.id));
      setPermissions(permissions.filter((permId) => !groupIds.has(permId)));
    }
  };

  const handlePermissionCheckbox = (id, checked) => {
    setPermissions((prevPermissions) =>
      checked
        ? [...prevPermissions, id]
        : prevPermissions.filter((permId) => permId !== id)
    );
  };

  const handleGroupToggle = (groupName) => {
    const isOpen = openGroup === groupName;
    setOpenGroup(isOpen ? null : groupName);
  };

  return (
    <div className="permissions-container">
      <Accordion open={openGroup} toggle={setOpenGroup}>
        {groupedPermissions.map((group) => (
          <AccordionItem key={group.groupName}>
            <AccordionHeader
              targetId={group.groupName}
              onClick={() => handleGroupToggle(group.groupName)}
            >
              <div className="d-flex gap-2 align-items-center">
                <Input
                  type="checkbox"
                  checked={groupNames[group.groupName] === true}
                  onClick={(e) => e.stopPropagation()}
                  onChange={(e) => handleGroupCheckbox(e.target.checked, group)}
                />
                <span>{group.groupName}</span>
              </div>
            </AccordionHeader>
            <AccordionBody accordionId={group.groupName}>
             <div className="permissions-grid">
             {group.elements.map((item) => (
                <div key={item.id} className="permission-item">
                  <Input
                    id={item.id}
                    type="checkbox"
                    checked={permissions.includes(item.id)}
                    onChange={(e) =>
                      handlePermissionCheckbox(item.id, e.target.checked)
                    }
                  />
                  <Label htmlFor={item.id}>{item.name}</Label>
                </div>
              ))}
             </div>
            </AccordionBody>
          </AccordionItem>
        ))}
      </Accordion>

      <style jsx>{`
        .permissions-container {
          padding: 10px;
        }
        .permissions-grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr); /* 3 items per row */
          gap: 10px; /* Space between items */
        }
        .permission-item {
          display: flex;
          align-items: start;
          margin-bottom: 10px;
        }
        .permission-item input {
          margin-right: 5px;
        }
        .permission-item label {
          font-size: 0.9rem;
        }
      `}</style>
    </div>
  );
};

export default PermissionsGroup;
