import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Button,
  Label,
  Input as ReactInput,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getModerationQuery, getProductQuery } from "../../../queries/index";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import { createModerationProduct } from "../../../api";
import { Textarea } from "../../../Components/atoms/textarea";
import { InputLabel } from "../../../Components/atoms/inputLabel";
import { config } from "../../../utils/config";
import Cleave from "cleave.js/react";
import { Input } from "../../../Components/atoms/input";

const values = {};
const schema = {};

const ModerProductView = () => {
  const { moderId } = useParams();
  const { t } = useTranslation();
  const QueryClient = useQueryClient();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const { data: product } = useQuery({
    ...getModerationQuery(moderId),
  });
  const {
    control,
    watch,
    formState: { errors },
    setValue,
  } = useHookForm(values, schema);

  const handleClick = (status) => {
    setLoading(true);
    createModerationProduct({ productId: moderId, status }).then(() => {
      setLoading(false);
      navigate("/moder-products");
      QueryClient.invalidateQueries(["moderations"]);
    });
  };

  useEffect(() => {
    if (product) {
      setValue("categoryName", product.category.name);
      product.names.map((item) =>
        setValue(`name${item.languageCode}`, item.text)
      );
      product.descriptions.map((item) =>
        setValue(`description${item.languageCode}`, item.text)
      );
      product.variations?.[0].prices.map((item) =>
        setValue(`price_${item.type}`, item.value)
      );
      product.variations?.[0].attributeValues.map((item) =>
        setValue(`value_${item.attribute?.name}`, item.value)
      );
    }
  }, [product]);
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  console.log(languages);

  document.title = "Moder Product Details | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("products")} pageTitle={t("home")} />
        {product?.id && (
          <Row>
            <Col className="d-flex justify-content-center">
              <Card className="w-75">
                <CardHeader>
                  <div className="d-flex align-items-center justify-content-between">
                    <div className="d-flex align-items-center gap-3">
                      <i
                        className="ri-arrow-left-line fs-3 cursor-pointer"
                        onClick={() => navigate(-1)}
                      ></i>
                      <h5
                        className="card-title flex-grow-1 mb-0"
                        style={{ fontSize: "20px", fontWeight: 700 }}
                      >
                        {t("products")}
                      </h5>
                    </div>
                    <div className="d-flex gap-3">
                      <Button
                        color="danger"
                        style={{ width: "150px" }}
                        outline
                        onClick={() => handleClick(3)}
                      >
                        {"✖ Отклонить товар"}
                      </Button>
                      <Button
                        color="success"
                        className="btn-animation"
                        data-text={"✔ Одобрить товар"}
                        style={{ width: "150px" }}
                        onClick={() => handleClick(1)}
                      >
                        <span>{"✔ Одобрить товар"}</span>
                      </Button>
                    </div>
                  </div>
                </CardHeader>
                <CardBody className="d-flex justify-content-between">
                  <Row className="d-flex flex-column gap-3 col-6">
                    <Input
                      name="categoryName"
                      control={control}
                      errors={errors}
                      label="category_name"
                      inputProps={{
                        readOnly: true,
                      }}
                    />
                    <div className="d-flex flex-column gap-3">
                      {languages.map((lang) => {
                        const item = product?.names?.find(
                          (name) => name.languageCode === lang
                        );
                        return (
                          <div key={lang}>
                            <Label className="fw-semibold">
                              {t(`name_${item.languageCode}`)}
                            </Label>
                            <ReactInput
                              value={item ? item.text : ""}
                              readOnly
                            />
                          </div>
                        );
                      })}
                    </div>
            
                    <div className="d-flex flex-column gap-3">
                      {languages.map((lang) => {
                        const item = product?.descriptions?.find(
                          (name) => name.languageCode === lang
                        );
                        return (
                          <div key={lang}>
                            <Label className="fw-semibold">
                              {t(`description_${item.languageCode}`)}
                            </Label>
                            <textarea
                              className="form-control p-3"
                              value={item ? item.text : ""}
                              readOnly
                            />
                          </div>
                        );
                      })}
                    </div>

                    <div className="d-flex flex-column gap-3">
                      {product?.variations[0]?.prices?.map((item) => (
                        <div key={item.id}>
                          <Label className="fw-semibold">
                            {t(`${item.type === "Sale" ? "MSRP" : item.type}`)}
                          </Label>
                          <Cleave
                            placeholder="Enter numeral"
                            options={{
                              numeral: true,

                              numeralThousandsGroupStyle: "thousand",
                            }}
                            value={item.value}
                            className="form-control border-dashed p-3"
                            readOnly
                          />
                        </div>
                      ))}
                    </div>
                    <div
                      style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(3, 1fr)",
                        gap: "5px",
                      }}
                    >
                      {product?.variations[0]?.files?.map((item) => (
                        <div
                     
                          id="img2"
                          key={item.languageCode}
                        >
                          <img
                            src={item.url}
                           className="img-thumbnail rounded avatar-xl"
                          />
                        </div>
                      ))}
                    </div>
                  </Row>
                  <Row className="d-flex flex-column gap-3 col-6">
                    {product?.variations[0]?.attributeValues.map(
                      (item) =>
                        item.attribute.isVisible &&
                        (!item.attribute.isValueTranslated ? (
                          <Input
                            key={item.id}
                            name={`value_${item.attribute?.name}`}
                            control={control}
                            errors={errors}
                            label={`${item.attribute?.name}`}
                            inputProps={{
                              readOnly: true,
                            }}
                          />
                        ) : (
                          item.attribute.isValueTranslated && (
                            <div>
                              <Label>{item.attribute?.name}</Label>

 
                               <div className="d-flex align-items-center gap-3">
                        {languages.map((lang) => {
                          const translation = item?.valueTranslations?.find(
                            (valueTranslation) => valueTranslation.languageCode === lang
                          );
                    
                          return (
                            translation && (
                              <div key={lang} className="w-100">
                                <InputLabel
                                  label={
                                    lang === "uz-Latn-UZ"
                                      ? t("uz")
                                      : lang === "uz-Cyrl-UZ"
                                      ? t("cryl")
                                      : t(lang)
                                  }
                                  inputProps={{
                                    value: translation.text,
                                    readOnly: true
                                  }}
                                />
                              </div>
                            )
                          );
                        })}
                      </div>
                            </div>
                          )
                        ))
                    )}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default ModerProductView;
