import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Row,
  CardHeader,
  Form,
  Button,
  Spinner,
  Label,
} from "reactstrap";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useTranslation } from "react-i18next";
import useHookForm from "../../../hooks/useHookForm";
import createSchema from "../../../helpers/createSchema";
import { createFilters, editFilter } from "../../../api";
import { getCategoriesQuery, getFiltersById, getFiltersByIdQuery } from "../../../queries";
import { Input } from "../../../Components/atoms/input";
import { useNavigate, useParams } from "react-router-dom";
import Cleave from "cleave.js/react";
import Select from "react-select";
import { Controller } from "react-hook-form";
import RadioButtonInput from "../ui/radio-button";
import { dataTypeIndex, findParentId } from "../../../helpers/findParentCategoryId";
import { config } from "../../../utils/config";
const isFood = config.SITENAME === "food"
const schema = createSchema({
  nameru: "name",
  "nameuz-Latn-UZ": "name",
  ...(isFood
    ? { nameen: "name" }
    : { "nameuz-Cyrl-UZ": "name" }),
  type: "select",
  values: "",
  weight: "number",
  dataType: "select",
});
const values = {
  name: {
    ru: "",
    "uz-Cyrl-UZ": "",
    "uz-Latn-UZ": "",
  },
  type: "",
  values: "",
  weight: "",
  dataType: "",
  categoryId: "",
  isVisible: "",
};

const FilterEdit = () => {
  const [onChange, setOnChange] = useState("");
  const [isVisible, setIsVisible] = useState(false);
  const {id} = useParams()
  const navigate = useNavigate();
  const { data: categories } = useQuery({
    ...getCategoriesQuery(),
  });

  const {data, isLoading} = useQuery({
    ...getFiltersByIdQuery(id)
  })

  
  
  const QueryClient = useQueryClient();
  
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  
  const {
      control,
      formState: { errors },
      handleSubmit,
      watch,
      reset,
      getValues,
      setValue,
      setError,
    } = useHookForm(values, schema);
   

  const onSubmit = handleSubmit((res) => {
    
    if (!watch("parentCategory")) {
      return setError("parentCategory", {
        type: "manual",
        message: t("required"),
      });
    }
    if (watch("type")?.value === "Price" || watch("type")?.value === "Range") {
      if (!watch("from") && !watch("to")) {
        return setError("fromTo", {
          type: "manual",
          message: t("required"),
        });
      }
    } else {
      if (!watch("value")) {
        return setError("value", {
          type: "manual",
          message: t("required"),
        });
      }
    }
    const names = languages.map((languageCode) => ({
      languageCode,
      text: res[`name${languageCode}`],
    }));
    
    let values = "";
    if (res.type?.value === "Price" || res.type?.value === "Range") {
      values = `${res.from || ""}-${res.to || ""}`; 
    } else {
      values = res.value;
    }
    setLoading(true);

    const datas = {
    id: id,
      name: names,
      weight: parseInt(res.weight),
      type: res.type.value,
      dataType: res.dataType.value,
      categoryId:
        res?.grandSonCategory?.value ||
        res?.childCategory?.value ||
        res?.parentCategory?.value,
      values,
      isVisible
    };
    editFilter(datas)
      .then(() => {
        setLoading(false);
        QueryClient.invalidateQueries(["filters"]);
        navigate("/filters");
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  });

  useEffect(() => {
    setValue("childCategory", "");
    setValue("grandSonCategory", "");
  }, [onChange]);

  useEffect(() => {
    if(data) {
        data.name.map((item) => (
            setValue(`name${item.languageCode}`, item.text)
        ))
        setValue(
            "parentCategory",
            findParentId(categories, data?.categoryId)?.parent ||
              findParentId(categories, data?.categoryId)
          );
          setValue(
            "childCategory",
            findParentId(categories, data.categoryId)?.child || {}
          );
          setValue(
            "grandSonCategory",
            findParentId(categories, data.categoryId)?.grandSon || {}
          );
          setValue("type", {
            value: data?.type,
            label: data?.type,
          });
          setValue("dataType", {
            value: data?.dataType,
            label: data?.dataType,
          });
          if (data?.type === "Price" || data?.type === "Range") {
            const [min, max] = data.values.split("-");
            setValue("from", min);
            setValue("to", max );
            setValue("value", ""); 
          } else {
            setValue("value", data?.values);
            setValue("from", "");
            setValue("to", "");   
          }
      
          
          setValue("weight", data?.weight)
          setIsVisible(data?.isVisible);
          
    }
  }, [data, setValue])
  let languages = ["ru", "uz-Cyrl-UZ", "uz-Latn-UZ"];
  if (config.SITENAME === "food") {
    languages.push("en");
    languages = languages.filter((lang) => lang !== "uz-Cyrl-UZ");
  }
  document.title = "Categories Create | Taqsim";
  return (
    <div className="page-content">
      <Container fluid>
        <Row>
          <Col xl={8}>
            <Card>
              <CardHeader>
                <div className="d-flex align-items-center gap-3">
                <i
              className="ri-arrow-left-line fs-3 cursor-pointer"
              onClick={() => navigate(-1)}
            ></i>
                  <h5
                    className="card-title flex-grow-1 mb-0"
                    style={{ fontSize: "20px", fontWeight: 700 }}
                  >
                    {t("add_filter")}
                  </h5>
                </div>
              </CardHeader>
              <CardBody>
                <Form action="#" className="d-flex flex-column gap-3">
                  <Label>{t("change_category")}</Label>
                  <Controller
                    name="parentCategory"
                    control={control}
                    className="bg-white"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={categories?.map((item) => ({
                          value: item.id,
                          label: item.name,
                          array: item,
                        }))}
                        placeholder={t("Select parent category")}
                        onChange={(selectedOption) => {
                          field.onChange(selectedOption);
                          setOnChange(selectedOption.value);
                        }}
                      />
                    )}
                  />
                  {errors.parentCategory && (
                    <span className="text-danger">
                      {errors.parentCategory.message}
                    </span>
                  )}
                  {watch("parentCategory")?.array && watch("parentCategory")?.array?.childs !== null && (
                    <Controller
                      name="childCategory"
                      control={control}
                      className="bg-white"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={watch("parentCategory")?.array?.childs?.map(
                            (item) => ({
                              value: item.id,
                              label: item.name,
                              array: item,
                            })
                          )}
                          placeholder={t("Select child category")}
                        />
                      )}
                    />
                  )}
                  {watch("childCategory")?.array && watch("childCategory")?.array?.childs !== null && (
                    <Controller
                      name="grandSonCategory"
                      control={control}
                      className="bg-white"
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={watch("childCategory")?.array?.childs?.map(
                            (item) => ({
                              value: item.id,
                              label: item.name,
                            })
                          )}
                          placeholder={t("Select child category")}
                        />
                      )}
                    />
                  )}
                  <Label>{t("filter_type")}</Label>
                  <Controller
                    name="type"
                    control={control}
                    className="bg-white"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: "Range", label: "Range" },
                          { value: "Price", label: "Price" },
                          { value: "MultipleSelect", label: "MultipleSelect" },
                          { value: "SingleSelect", label: "SingleSelect" },
                        ]}
                        placeholder={t("Select filter type")}
                      />
                    )}
                  />
                  {errors.type && (
                    <span
                      style={{ fontSize: "0.71rem" }}
                      className="text-danger"
                    >
                      {t(errors.type.message)}
                    </span>
                  )}
                  <Label>{t("dataType")}</Label>
                  <Controller
                    name="dataType"
                    control={control}
                    className="bg-white"
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={[
                          { value: "Text", label: "Text" },
                          { value: "Integer", label: "Integer" },
                          { value: "Float", label: "Float" },
                          { value: "Date", label: "Date" },
                          { value: "Bool", label: "Bool" },
                          { value: "Char", label: "Char" },
                        ]}
                        placeholder={t("Select data type")}
                      />
                    )}
                  />
                  {errors.dataType && (
                    <span
                      style={{ fontSize: "0.71rem" }}
                      className="text-danger"
                    >
                      {t(errors.dataType.message)}
                    </span>
                  )}
                  <Input
                    control={control}
                    errors={errors}
                    name="weight"
                    label="weight"
                    inputProps={{
                      placeholder: t("enter weight"),
                    }}
                    isInteger
                    maxLength={1}
                  />
                  <Label>{t("key_value_filter")}</Label>
                  {watch("type")?.value === "Price" ||
                  watch("type")?.value === "Range" ? (
                    <>
                      <div className="d-flex justify-content-between">
                        <div className="d-flex align-items-end gap-3">
                          <Input
                            control={control}
                            errors={errors}
                            name="from"
                            inputProps={{
                              placeholder: t("enter value"),
                            }}
                          />
                          <span>dan</span>
                        </div>
                        <div className="d-flex align-items-end gap-3">
                          <Input
                            control={control}
                            errors={errors}
                            name="to"
                            inputProps={{
                              placeholder: t("enter value"),
                            }}
                          />
                          <span>gacha</span>
                        </div>
                      </div>
                      {errors.fromTo && (
                        <span className="text-danger">
                          {errors.fromTo.message}
                        </span>
                      )}
                    </>
                  ) : (
                    <Input
                      control={control}
                      errors={errors}
                      name="value"
                      inputProps={{
                        placeholder: t("enter value"),
                      }}
                    />
                  )}
              
                  
                  <div className="d-flex flex-column gap-3">
                    {languages.map((langCode) => (
                      <div key={langCode}>
                       
                        <Input
                        label={t(`name_${langCode}`)}
                          control={control}
                          errors={errors}
                          name={`name${langCode}`}
                          inputProps={{
                            placeholder: t("enter_name"),
                          }}
                        />
                       
                      </div>
                    ))}

                 
                  </div>
                    <RadioButtonInput
                    label={"isVisible"}
                    state={isVisible}
                    setState={setIsVisible}
                    
                    id={1}
                  />
                  <Button
                    color="success"
                    className="btn btn-
                     w-100"
                    type="submit"
                    onClick={onSubmit}
                  >
                    {loading ? (
                      <Spinner size="sm" className="me-2">
                        {" "}
                        Loading...{" "}
                      </Spinner>
                    ) : null}
                    {t("save")}
                  </Button>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FilterEdit;
