import React from 'react'
import { Button, Col, Input, Row } from 'reactstrap'

const Paginations = ({pagination}) => {
    
  return (
    <div>
      {pagination && (
        <Row className="justify-content-md-end justify-content-center align-items-center p-2">
          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() =>
                  pagination?.onChange(pagination?.currentPage - 1)
                }
                disabled={pagination?.currentPage <= 1}
              >
                {"<"}
              </Button>
            </div>
          </Col>
          <Col className="col-md-auto d-none d-md-block">
            Page{" "}
            <strong>
              {pagination?.currentPage} of {pagination?.totalPages}
            </strong>
          </Col>
          <Col className="col-md-auto">
            <Input
              type="number"
              min={1}
              style={{ width: 70 }}
              max={pagination?.totalPages}
              defaultValue={pagination?.currentPage}
              readOnly
            />
          </Col>

          <Col className="col-md-auto">
            <div className="d-flex gap-1">
              <Button
                color="primary"
                onClick={() =>
                  pagination?.onChange(pagination?.currentPage + 1)
                }
                disabled={pagination?.totalPages === pagination?.currentPage}
              >
                {">"}
              </Button>
            </div>
          </Col>
        </Row>
      )}
    </div>
  )
}

export default Paginations
