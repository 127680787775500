import React, { useMemo, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import BreadCrumb from "../../../Components/Common/BreadCrumb";
import { useQuery } from "@tanstack/react-query";
import { getBrandQuery } from "../../../queries";
import { useTranslation } from "react-i18next";
import TableContainer from "../../../Components/Common/TableContainer";
import Loader from "../../../Components/Common/Loader";
import { prettify } from "../../../helpers/price-formater";
import Paginations from "../ui/pagination";
const values = {
  page: 1,
  size: 20,
};
const Brand = () => {
  const [formValues, setFormValues] = useState(values);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data, isFetched } = useQuery({
    ...getBrandQuery(formValues),
  });

  console.log(data);

  const handlePageSizeChange = (e) => {
    const newSize = parseInt(e.target.value);
    setFormValues((prevState) => ({
      ...prevState,
      size: newSize,
      page: 1,
    }));
  };

  const handlePageChange = (page) => {
    setFormValues((prevState) => ({ ...prevState, page }));
  };

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title={t("brands")} pageTitle={t("home")} />
        <div className="d-flex justify-content-end mb-3">
          <Link to={"/brand/create"} className="d-flex align-items-center">
            <span>{t("add")}</span>
            <btn
              className="ri-add-line fs-4 p-1"
              style={{ cursor: "pointer" }}
            />
          </Link>
        </div>
        <Row>
          {isFetched ? (
            data?.map((item) => (
              <Col
                key={item.id}
                xs="12"
                sm="6"
                md="5"
                lg="4"
                xl="3"
                className="mb-4 d-flex justify-content-center"
              >
                <Card
                  style={{
                    borderRadius: "8px",
                    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                    width: "100%",
                    maxWidth: "18rem",
                    cursor: "pointer",
                  }}
                  onClick={() => navigate(`/brand/${item.id}`)}
                >
                  <div
                    className="card-image-container"
                    style={{
                      position: "relative",
                      width: "100%",
                      height: "200px",
                      overflow: "hidden",
                    }}
                  >
                    <img
                      src={item?.image?.url}
                      alt="Product"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderTopLeftRadius: "8px",
                        borderTopRightRadius: "8px",
                      }}
                    />
                  </div>
                  <CardBody>
                    <CardSubtitle
                      className="mb-2"
                      style={{
                        textAlign: "start",
                        fontSize: "1rem",
                        color: "#666",
                      }}
                    >
                      {item?.name}
                    </CardSubtitle>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <span
                        style={{
                          width: "auto",
                          display: "flex",
                          justifyContent: "center",
                          background:
                            item?.isVisible === false ? "red" : "green",
                          color: "white",
                          borderRadius: "10px",
                          fontSize: "0.7rem",
                          padding: "4px 12px",
                        }}
                      >
                        {item?.isVisible === false
                          ? t("invisible")
                          : t("visible")}
                      </span>
                      <Button
                        color="light"
                        style={{
                          padding: "0px 16px",
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/brand/edit/${item.id}`);
                        }}
                      >
                        <i className="ri-edit-line fs-16"></i>
                      </Button>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            ))
          ) : (
            <Loader />
          )}
          <Paginations
            pagination={{
              currentPage: formValues?.page,
              totalPages: data?.pagination?.TotalPages,
              onChange: handlePageChange,
            }}
          />
        </Row>
      </Container>
    </div>
  );
};

export default Brand;
const css = {
  steteStyle: {
    color: "white",
    borderRadius: "10px",
    fontSize: "0.7rem",
    padding: "2px 4px",
  },
};
